












































































import { Component, Vue } from "vue-property-decorator";
import UserService from "@/api/user";

@Component
export default class Register extends Vue {
  user: any = {
    name: "",
    last_name: "",
    email: "",
    password: ""
  };
  hasEmptyValues = false;
  password_confirm = "";

  validateData(): void {
    this.hasEmptyValues = false;
    Object.values(this.user).forEach(value => {
      if (value == "") {
        this.hasEmptyValues = true;
      }
    });
    if (this.hasEmptyValues) {
      this.$buefy.notification.open({
        message: "Los campos no pueden estar vacios",
        type: "is-danger"
      });
      return;
    }
    if (this.user.password != this.password_confirm) {
      this.$buefy.notification.open({
        message: "Las contraseñas deben coincidir",
        type: "is-danger"
      });
      return;
    }
  }

  focusNextSibling(ref: string): void {
    ((this.$refs[ref] as Vue).$el.firstChild as HTMLElement).focus();
  }

  createUser(): void {
    this.validateData();
    UserService.create(this.user)
      .then(response => {
        this.$router.push({
          name: "Login",
          params: {
            email: response.data.email
          }
        });
      })
      .catch(() =>
        this.$buefy.notification.open({
          message: "Ha ocurrido un error al crear el usuario",
          type: "is-danger"
        })
      );
  }
}
